<template>
  <div>
    <b-card title="DWG Extranet - Organization Management">
      <b-card-text>
        Welcome to the DWG Extranet organization management portal, from here you can update your company profile and manage your users.
      </b-card-text>
    </b-card>
    <b-row>
      <b-col lg="6" xl="4">
        <b-card title="Updating your company profile">
          <div style="position: relative; padding-top: 53.75%;">
            <iframe
              src="https://customer-sl84kv5adgar105t.cloudflarestream.com/968d72ebd097890834d4ecc16c601e7d/iframe?poster=https%3A%2F%2Fcustomer-sl84kv5adgar105t.cloudflarestream.com%2F968d72ebd097890834d4ecc16c601e7d%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
              loading="lazy" style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"></iframe>
          </div>
        </b-card>
      </b-col>
      <b-col lg="6" xl="4">
        <b-card title="Adding a new user">
          <div style="position: relative; padding-top: 53.75%;">
            <iframe
              src="https://customer-sl84kv5adgar105t.cloudflarestream.com/d3ab2150bdc2aebc9f2d374b683c16f7/iframe?poster=https%3A%2F%2Fcustomer-sl84kv5adgar105t.cloudflarestream.com%2Fd3ab2150bdc2aebc9f2d374b683c16f7%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
              loading="lazy" style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"></iframe>
          </div>
        </b-card>
      </b-col>
      <b-col lg="6" xl="4">
        <b-card title="How to delete a user">
          <div style="position: relative; padding-top: 53.75%;">
            <iframe
              src="https://customer-sl84kv5adgar105t.cloudflarestream.com/9cdf8f5b825bad70f7d2504b044ebd8c/iframe?poster=https%3A%2F%2Fcustomer-sl84kv5adgar105t.cloudflarestream.com%2F9cdf8f5b825bad70f7d2504b044ebd8c%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
              loading="lazy" style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"></iframe>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardText, BRow, BCol } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'

export default {
  setup() {
    const userData = ref({})
    userData.value = useJwt.getUserData()

    return {
      userData,
    }
  },
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
  },
}
</script>

<style>

</style>
